import * as _ from "lodash";
import visits from "./visited-places.json";

interface Place {
  title: string;
  location: string;
  date: string;
  latLong: string;
}

// interface LocationData {
//   name?: string;
//   title?: string;
//   places: Place[];
// }

const flatArray: Place[] = _.flatMap(visits, (item) => item.places);

export const visitedPlacesData = [...flatArray];
