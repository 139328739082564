import React, { useState } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Layout from "./components/Layout";
import { visitedPlacesData } from "./constants/visits"; // Import your JSON
import Icon from "./assets/marker.png";
import { formatDate } from "./utils/date";

// Custom Marker Icon (Google-like marker)
import L from "leaflet";
const customIcon = new L.Icon({
  iconUrl: Icon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

// Component to fit map bounds based on markers
function FitBounds({ places }: any) {
  const map = useMap();

  React.useEffect(() => {
    if (places.length > 0) {
      const bounds = L.latLngBounds(
        places.map((place: any) => {
          const [lat, long] = place.latLong.split(",");
          return [lat, long];
        })
      );
      map.fitBounds(bounds);
    }
  }, [map, places]);

  return null;
}

function App() {
  const [visitedPlaces] = useState(visitedPlacesData);

  return (
    <Layout>
      <section id="about" className="about-section">
        <div className="container">
          <h2>About Me</h2>
          <p>
            Hi, I'm Rahul Shandilya, a software engineer and a travel
            enthusiast. This website showcases the places I've visited around
            the world. Below, you can find my interactive travel map that tracks
            my journeys.
          </p>
        </div>
      </section>

      <section id="map">
        <div className="container">
          <h2>Travel Map</h2>
          <MapContainer
            center={[20, 0]}
            zoom={2}
            style={{ height: "500px", width: "100%" }}
          >
            <TileLayer
              url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=dAOew7uMjjFxoeBaJQaj"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {visitedPlaces.map((place: any, idx) => {
              const [lat, long] = place.latLong.split(",");
              return (
                <Marker key={idx} position={[lat, long]} icon={customIcon}>
                  <Popup>
                    <strong>{place.title}</strong>
                    <br />
                    {place.location}
                    <br />
                    {formatDate(place.date)}
                  </Popup>
                </Marker>
              );
            })}
            <FitBounds places={visitedPlaces} />
          </MapContainer>
        </div>
      </section>
    </Layout>
  );
}

export default App;
